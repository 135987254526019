import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RequestableFoSplInventParametersDto, requestableFoSplInventParametersDtoFields } from '@app/core/models/dto/fo/fo.splInventParameter.dto';
import { FoBaseClientApiService } from '@services/finops/fo-base.service';
import { D365Entities } from '@app/core/models/dto/D365Entities.enum';
import { ODataArrayResult } from '@app/core/models/dto/odata-result';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FoInventParameterService extends FoBaseClientApiService <RequestableFoSplInventParametersDto>{
  protected override getMainEntityName(): string {
    return D365Entities.SPLInventParameters;
  }
    /**
     * Retrieves some inventory parameters from the FoODataService based on the provided filter parameters.
     *
     * @param {unknown} [filterParams] - The filter parameters to apply to the query.
     * @param {string[]} [orderBy] - The fields to order the results by.
     * @param {string[]} [expand] - The fields to expand in the results.
     * @param {boolean} [crossCompany=false] - Whether to cross company boundaries.
     * @param {number | undefined} [top] - The maximum number of results to return.
     * @return {Observable<ODataArrayResult<RequestableFoSplInventParametersDto>>} An observable that emits the ODataResult of RequestableFoSplInventParameterDto entities.
     */
  getSomeInventParameters( filterParams?: unknown, orderBy?: string[], expand?: string[], crossCompany?: boolean, top?: number | undefined): Observable<ODataArrayResult<RequestableFoSplInventParametersDto>> {
    return this.foODataService.getSomeWithFilterParams<RequestableFoSplInventParametersDto>(this.getMainEntityName(),requestableFoSplInventParametersDtoFields,filterParams,orderBy,expand,crossCompany,top);
  }

  /**
   * Retrieves some inventory parameters by dataArea ID.
   *
   * @param {string} dataAreaId - The ID of the data area. Defaults to an empty string.
   * @return {Observable<ODataArrayResult<RequestableFoSplInventParametersDto>>} An observable that emits the ODataResult of RequestableFoSplInventParameterDto entities.
   */
  getSomeInventParamtersByDataAreaId(dataAreaId: string = ''): Observable<ODataArrayResult<RequestableFoSplInventParametersDto>>{
    if(dataAreaId === '') return of(this.getDefaultOdataArrayResultValue());
    return this.getSomeInventParameters({dataAreaId : dataAreaId}, undefined, undefined, true);
  }


  /**
   * Retrieves all inventory parameters from the FoODataService.
   *
   * @return {Observable<ODataArrayResult<RequestableFoSplInventParametersDto>>} An observable that emits the ODataResult of RequestableFoSplInventParameterDto entities.
   */
  getAllInventParameters(): Observable<ODataArrayResult<RequestableFoSplInventParametersDto>>{
    return this.getSomeInventParameters(undefined, undefined, undefined, true);
  }

  /**
   * Retrieves some inventory parameters from the FoODataService based on the provided filter parameters.
   * supplier company is retrieved by environment variable
   * @return {Observable<ODataArrayResult<RequestableFoSplInventParameterDto>>} An observable that emits the ODataResult of RequestableFoSplInventParameterDto entities.
   */
  getSupplierInventParameters(): Observable<ODataArrayResult<RequestableFoSplInventParametersDto>>{
    return this.getSomeInventParameters({dataAreaId : environment.fo.supplierCompany}, undefined, undefined, true);
  }

}
