/**
 * Enum representing various HTTP status codes.
 */
export enum HttpStatusCode {
  /**
   * The server has received the request headers and the client should proceed to send the request body.
   */
  Continue = 100,
  /**
   * The requester has asked the server to switch protocols and the server has agreed to do so.
   */
  SwitchingProtocols = 101,
  /**
   * The server is processing the request, but no response is available yet.
   */
  Processing = 102,
  /**
   * Indicates to the client that the server is likely to send a final response with the header fields included in the informational response of the current status code.
   */
  EarlyHints = 103,
  /**
   * Standard response for successful HTTP requests.
   */
  OK = 200,
  /**
   * The request has been fulfilled, resulting in the creation of a new resource.
   */
  Created = 201,
  /**
   * The request has been accepted for processing, but the processing has not been completed.
   */
  Accepted = 202,
  /**
   * The server successfully processed the request but is returning information that may be from another source.
   */
  NonAuthoritativeInformation = 203,
  /**
   * The server successfully processed the request but is not returning any content.
   */
  NoContent = 204,
  /**
   * The server successfully processed the request but is resetting the document view.
   */
  ResetContent = 205,
  /**
   * The server is delivering only part of the resource due to a range header sent by the client.
   */
  PartialContent = 206,
  /**
   * Indicates multiple status values for a single HTTP request.
   */
  MultiStatus = 207,
  /**
   * The members of a DAV binding have already been enumerated in a preceding part of the (multistatus) response, and are not being included again.
   */
  AlreadyReported = 208,
  /**
   * The server successfully processed the request and the response is intentionally blank.
   */
  IMUsed = 226,
  /**
   * Indicates multiple options for the resource that the client may follow.
   */
  MultipleChoices = 300,
  /**
   * This and all future requests should be directed to the given URI.
   */
  MovedPermanently = 301,
  /**
   * The resource can be found under a different URI.
   */
  Found = 302,
  /**
   * The response to the request can be found under a different URI and should be retrieved using a GET method on that resource.
   */
  SeeOther = 303,
  /**
   * Indicates that the resource has not been modified since the version specified by the request headers.
   */
  NotModified = 304,
  /**
   * The requested resource is available only through a proxy, whose address is provided in the response.
   */
  UseProxy = 305,
  /**
   * The requested resource temporarily resides under a different URI.
   */
  TemporaryRedirect = 307,
  /**
   * Indicates that the resource has been permanently moved to another location, and the client should issue a new request to that location.
   */
  PermanentRedirect = 308,
  /**
   * The server cannot or will not process the request due to an apparent client error.
   */
  BadRequest = 400,
  /**
   * Similar to 403 Forbidden, but specifically for use when authentication is required and has failed or has not yet been provided.
   */
  Unauthorized = 401,
  /**
   * Reserved for future use.
   */
  PaymentRequired = 402,
  /**
   * The client does not have access rights to the content, i.e., they are unauthorized to view it.
   */
  Forbidden = 403,
  /**
   * The server can not find the requested resource.
   */
  NotFound = 404,
  /**
   * The method specified in the request is not allowed for the resource identified by the request URI.
   */
  MethodNotAllowed = 405,
  /**
   * The resource identified by the request is only capable of generating response entities that have content characteristics not acceptable according to the accept headers sent in the request.
   */
  NotAcceptable = 406,
  /**
   * Similar to 401 Unauthorized, but it indicates that the client needs to authenticate itself to get the requested response.
   */
  ProxyAuthenticationRequired = 407,
  /**
   * The client did not produce a request within the time that the server was prepared to wait.
   */
  RequestTimeout = 408,
  /**
   * Indicates that the request could not be processed because of conflict in the current state of the resource, such as an edit conflict between multiple simultaneous updates.
   */
  Conflict = 409,
  /**
   * Indicates that the resource requested is no longer available and will not be available again.
   */
  Gone = 410,
  /**
   * The request did not specify the length of its content, which is required by the requested resource.
   */
  LengthRequired = 411,
  /**
   * The server does not meet one of the preconditions that the requester put on the request.
   */
  PreconditionFailed = 412,
  /**
   * The request is larger than the server is willing or able to process.
   */
  PayloadTooLarge = 413,
  /**
   * The URI provided was too long for the server to process.
   */
  URITooLong = 414,
  /**
   * The request entity has a media type which the server or resource does not support.
   */
  UnsupportedMediaType = 415,
  /**
   * The client has asked for a portion of the file, but the server cannot supply that portion.
   */
  RangeNotSatisfiable = 416,
  /**
   * The server cannot meet the requirements of the Expect request-header field.
   */
  ExpectationFailed = 417,
  /**
   * This code was defined in 1998 as one of the traditional IETF April Fools' jokes, in RFC 2324, Hyper Text Coffee Pot Control Protocol, and is not expected to be implemented by actual HTTP servers.
   */
  ImATeapot = 418,
  /**
   * The request was directed at a server that is not able to produce a response.
   */
  MisdirectedRequest = 421,
  /**
   * The request was well-formed but was unable to be followed due to semantic errors.
   */
  UnprocessableEntity = 422,
  /**
   * The resource that is being accessed is locked.
   */
  Locked = 423,
  /**
   * The request failed due to failure of a previous request (e.g., a PROPPATCH).
   */
  FailedDependency = 424,
  /**
   * The server refuses to accept the request without a defined Content-Length.
   */
  TooEarly = 425,
  /**
   * The client should switch to a different protocol such as TLS/1.0, given in the Upgrade header field.
   */
  UpgradeRequired = 426,
  /**
   * The origin server requires the request to be conditional.
   */
  PreconditionRequired = 428,
  /**
   * The user has sent too many requests in a given amount of time.
   */
  TooManyRequests = 429,
  /**
   * The server is unwilling to process the request because either an individual header field, or all the header fields collectively, are too large.
   */
  RequestHeaderFieldsTooLarge = 431,
  /**
   * A server operator has received a legal demand to deny access to a resource or to a set of resources that includes the requested resource.
   */
  UnavailableForLegalReasons = 451,
  /**
   * A generic error message, given when an unexpected condition was encountered and no more specific message is suitable.
   */
  InternalServerError = 500,
  /**
   * The server either does not recognize the request method, or it lacks the ability to fulfill the request.
   */
  NotImplemented = 501,
  /**
   * The server was acting as a gateway or proxy and received an invalid response from the upstream server.
   */
  BadGateway = 502,
  /**
   * The server is currently unable to handle the request due to a temporary overload or maintenance of the server.
   */
  ServiceUnavailable = 503,
  /**
   * The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.
   */
  GatewayTimeout = 504,
  /**
   * The server does not support, or refuses to support, the major version of HTTP that was used in the request.
   */
  HTTPVersionNotSupported = 505,
  /**
   * Transparent content negotiation for the request results in a circular reference.
   */
  VariantAlsoNegotiates = 506,
  /**
   * The server is unable to store the representation needed to complete the request.
   */
  InsufficientStorage = 507,
  /**
   * The server detected an infinite loop while processing the request.
   */
  LoopDetected = 508,
  /**
   * Further extensions to the request are required for the server to fulfill it.
   */
  NotExtended = 510,
  /**
   * The client needs to authenticate to gain network access.
   */
  NetworkAuthenticationRequired = 511
}
