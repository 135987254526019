import { Injectable, signal } from '@angular/core';
import { TOAST_STATE } from '../types/alert.type';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private severity = signal<string>(TOAST_STATE.info);
  private displayed = signal<boolean>(false);
  private title = signal<string>('Default title');
  private description = signal<string>('');
  private expanded = signal<boolean>(false);
  private displayTime = signal<number>(5000);

  /**
   * @param severity 
   * @param title 
   * @param description 
   * @param expanded 
   * @param displayTime 
   * @returns void
   * @description Displays an alert with the specified type, title, and optional message for a specific duration.
   **/
  show(severity: string, expanded: boolean = false, title: string, description: string = '', displayTime: number = 5000): void {
    this.severity.set(severity || TOAST_STATE.info);
    this.displayed.set(true);
    this.title.set(title);
    this.description.set(description || '');
    this.expanded.set(expanded || false);
    this.displayTime.set(displayTime || 0);

    if (this.displayTime() > 0) {
      setTimeout(() => {
        this.displayed.set(false);
      }, this.displayTime());
    }
  }

  /**
   * @returns void
   * @description Toggles the expanded state of the component and updates the visibility of the alert accordingly
   **/
  toggle(): void {
    this.expanded.set(!this.expanded());
  }

  /**
   * @returns void
   * @description Closes the alert by setting displayed to false
   **/
  close(): void {
    this.displayed.set(false);
  }

}
