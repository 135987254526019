export interface FoSPLInventParametersDto {
  "@odata.etag": string;
  dataAreaId: string;
  AreSiteSpecificMainAccountsEnabled: string;
  DefaultBOMJournalNameId: string;
  SPLPdsBatchAttribGroupId: string;
  LengthDefinedUnitSymbol: string;
  InventoryClosingExtraBatchHelperAmount: number;
  AreQualityManagementProcessesEnabled: string;
  IsOrderedInventoryQuantityReservationAllowed: string;
  DefaultInventoryMovementJournalNameId: string;
  VolumeDefinedUnitSymbol: string;
  DefaultInventoryAdjustmentJournalNameId: string;
  WillPickingWorkbenchApplyBoxingLogic: string;
  InventoryClosingItemBundleSize: number;
  DefaultInventoryUnitSymbol: string;
  PriceActivationItemBundleSize: number;
  WillTransferOrderEntryAutomaticallyReserveItems: string;
  SPLHolderNumSeqId: string;
  OverrideFEFODateControlForTransferOrders: string;
  WillInventoryUpdateAutomaticallyAggregateTransactions: string;
  SPLInventSiteId: string;
  DefaultBatchMergeBOMJournalNameId: string;
  InventoryClosingHelperBatchGroupId: string;
  IsItemSalesTaxGroupMandatory: string;
  SPLEquipmentModelsHierarchy: number;
  DefaultCatchWeightJournalNameId: string;
  PrintedPickingListPickingRouteIdBarcodeSetupId: string;
  SaveLastPriceHistory: string;
  MaximumAllowedUnitCost: number;
  ArePackingMaterialFeesCalculated: string;
  DefaultInventoryCountingJournalNameId: string;
  SPLInventLocationId: string;
  PostPhysicalSalesTax: string;
  QualityOrderWorkInventoryDimensionPolicy: string;
  CostBreakdownType: string;
  DefaultOwnershipChangeJournalNameId: string;
  WillQualityTestResultsUpdateBatchAttributes: string;
  ProductionVarianceCalculationModel: string;
  WillOrderPickingAutomaticallyEndInventoryOutputOrders: string;
  SPLDisplayHolderName: string;
  QualityTestHourlyRate: number;
  WillInventoryCountingLockItems: string;
  WillTransferOrderProcessesAcceptOverdelivery: string;
  MassDefinedUnitSymbol: string;
  DefaultInventoryTransferJournalNameId: string;
  TransferOrderPickingRouteStatusOnUpdate: string;
  WillTransferOrderProcessesAcceptUnderdelivery: string;
  DefaultInventoryTagCountingJournalNameId: string;
  FallbackWarehouseId: string;
}
export enum FoSplInventParametersDtoFields {
  '@odata.etag' = '@odata.etag',
  DataAreaId = 'dataAreaId',
  AreSiteSpecificMainAccountsEnabled = 'AreSiteSpecificMainAccountsEnabled',
  DefaultBOMJournalNameId = 'DefaultBOMJournalNameId',
  SPLPdsBatchAttribGroupId = 'SPLPdsBatchAttribGroupId',
  LengthDefinedUnitSymbol = 'LengthDefinedUnitSymbol',
  InventoryClosingExtraBatchHelperAmount = 'InventoryClosingExtraBatchHelperAmount',
  AreQualityManagementProcessesEnabled = 'AreQualityManagementProcessesEnabled',
  IsOrderedInventoryQuantityReservationAllowed = 'IsOrderedInventoryQuantityReservationAllowed',
  DefaultInventoryMovementJournalNameId = 'DefaultInventoryMovementJournalNameId',
  VolumeDefinedUnitSymbol = 'VolumeDefinedUnitSymbol',
  DefaultInventoryAdjustmentJournalNameId = 'DefaultInventoryAdjustmentJournalNameId',
  WillPickingWorkbenchApplyBoxingLogic = 'WillPickingWorkbenchApplyBoxingLogic',
  InventoryClosingItemBundleSize = 'InventoryClosingItemBundleSize',
  DefaultInventoryUnitSymbol = 'DefaultInventoryUnitSymbol',
  PriceActivationItemBundleSize = 'PriceActivationItemBundleSize',
  WillTransferOrderEntryAutomaticallyReserveItems = 'WillTransferOrderEntryAutomaticallyReserveItems',
  SPLHolderNumSeqId = 'SPLHolderNumSeqId',
  OverrideFEFODateControlForTransferOrders = 'OverrideFEFODateControlForTransferOrders',
  WillInventoryUpdateAutomaticallyAggregateTransactions = 'WillInventoryUpdateAutomaticallyAggregateTransactions',
  SPLInventSiteId = 'SPLInventSiteId',
  DefaultBatchMergeBOMJournalNameId = 'DefaultBatchMergeBOMJournalNameId',
  InventoryClosingHelperBatchGroupId = 'InventoryClosingHelperBatchGroupId',
  IsItemSalesTaxGroupMandatory = 'IsItemSalesTaxGroupMandatory',
  SPLEquipmentModelsHierarchy = 'SPLEquipmentModelsHierarchy',
  DefaultCatchWeightJournalNameId = 'DefaultCatchWeightJournalNameId',
  PrintedPickingListPickingRouteIdBarcodeSetupId = 'PrintedPickingListPickingRouteIdBarcodeSetupId',
  SaveLastPriceHistory = 'SaveLastPriceHistory',
  MaximumAllowedUnitCost = 'MaximumAllowedUnitCost',
  ArePackingMaterialFeesCalculated = 'ArePackingMaterialFeesCalculated',
  DefaultInventoryCountingJournalNameId = 'DefaultInventoryCountingJournalNameId',
  SPLInventLocationId = 'SPLInventLocationId',
  PostPhysicalSalesTax = 'PostPhysicalSalesTax',
  QualityOrderWorkInventoryDimensionPolicy = 'QualityOrderWorkInventoryDimensionPolicy',
  CostBreakdownType = 'CostBreakdownType',
  DefaultOwnershipChangeJournalNameId = 'DefaultOwnershipChangeJournalNameId',
  WillQualityTestResultsUpdateBatchAttributes = 'WillQualityTestResultsUpdateBatchAttributes',
  ProductionVarianceCalculationModel = 'ProductionVarianceCalculationModel',
  WillOrderPickingAutomaticallyEndInventoryOutputOrders = 'WillOrderPickingAutomaticallyEndInventoryOutputOrders',
  SPLDisplayHolderName = 'SPLDisplayHolderName',
  QualityTestHourlyRate = 'QualityTestHourlyRate',
  WillInventoryCountingLockItems = 'WillInventoryCountingLockItems',
  WillTransferOrderProcessesAcceptOverdelivery = 'WillTransferOrderProcessesAcceptOverdelivery',
  MassDefinedUnitSymbol = 'MassDefinedUnitSymbol',
  DefaultInventoryTransferJournalNameId = 'DefaultInventoryTransferJournalNameId',
  TransferOrderPickingRouteStatusOnUpdate = 'TransferOrderPickingRouteStatusOnUpdate',
  WillTransferOrderProcessesAcceptUnderdelivery = 'WillTransferOrderProcessesAcceptUnderdelivery',
  DefaultInventoryTagCountingJournalNameId = 'DefaultInventoryTagCountingJournalNameId',
  FallbackWarehouseId = 'FallbackWarehouseId'
}
export const requestableFoSplInventParametersDtoFields = [
  FoSplInventParametersDtoFields.DataAreaId,
  FoSplInventParametersDtoFields.SPLInventSiteId,
  FoSplInventParametersDtoFields.SPLInventLocationId
];
export interface RequestableFoSplInventParametersDto {
  dataAreaId: string;
  SPLInventSiteId: string;
  SPLInventLocationId: string;
}
