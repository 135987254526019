import { Injectable, inject } from '@angular/core';
import { map, type Observable } from 'rxjs';
import buildQuery from 'odata-query';
import { LoggerService } from '@services/log/logger.service';
import { D365GatewayService } from '@services/finops/D365-gateway.service';
import { HttpMethod } from '@app/core/models/dto/gateway-midleware/HttpMethod.enum';
import { OdataCountResult, type ODataArrayResult } from '@app/core/models/dto/odata-result';
import { D365GatewayResponse } from '@app/core/models/dto/gateway-midleware/d365-gateway-response';
import { KeyValue } from '@angular/common';
import { environment } from '@environments/environment';

// Cette classe interagit avec l'API OData en passant par le gateway D365.
@Injectable({
  providedIn: 'root'
})
export class FoOdataService {
  private readonly d365GatewayService: D365GatewayService = inject(D365GatewayService);
  private readonly logger: LoggerService = inject(LoggerService);
  private readonly batchID: string = environment.fo.batchId;
  private readonly queryLogIsEnabled = environment.enableQueryDebug;

  //#region Base methodes

  /**
   * Retrieves entities from the server based on the provided endpoint query.
   *
   * @param {string} endpointQuery - The query string for the endpoint.
   * @param {boolean} [isQuiet=false] - Indicates whether to suppress logging.
   * @template T - The type of data to be retrieved.
   * @returns {Observable<ODataArrayResult<T>>} An Observable that emits the retrieved entities.
   */
  private GetEntities<T>(endpointQuery: string, isQuiet = false): Observable<ODataArrayResult<T>> {
    if (this.queryLogIsEnabled) this.logger.debug(endpointQuery);
    try {
      const gatewayRequest = this.d365GatewayService.prepareGatewayRequest(endpointQuery, HttpMethod.GET);
      return this.d365GatewayService.sendGatewayRequest<ODataArrayResult<T>>(gatewayRequest, isQuiet).pipe(
        map((gatewayResponse: D365GatewayResponse<ODataArrayResult<T>>) => gatewayResponse.response),
      );
    } catch (error) {
      console.error('Error making request:', error);
      throw error;
    }
  }

  /**
   * Sends a POST request to the specified endpoint with the provided body.
   *
   * @param {string} endpointQuery - The query string for the endpoint.
   * @param {unknown} [body=undefined] - The body of the request.
   * @param {boolean} [isQuiet=false] - Indicates whether to suppress logging.
   * @returns {Observable<D365GatewayResponse<T>>} An Observable of the response from the server.
   */
  private postEntity<T>(endpointQuery: string, body: unknown = undefined, isQuiet = false): Observable<D365GatewayResponse<T>> {
    if (this.queryLogIsEnabled) this.logger.debug(endpointQuery);
    try {
      const gatewayRequest = this.d365GatewayService.prepareGatewayRequest(endpointQuery, HttpMethod.POST, body);
      if (this.queryLogIsEnabled) this.logger.displayObjectDebug(gatewayRequest, ' FoOadataService postEntity : gatewayRequest');
      return this.d365GatewayService.sendGatewayRequest<T>(gatewayRequest, isQuiet);
    } catch (error) {
      console.error('Error making request:', error);
      throw error;
    }
  }

  /**
   * Sends a POST request in a batch format to the server.
   *
   * @param body - An object containing the value and batch ID for the request.
   * @param isQuiet - A boolean indicating whether to suppress logging (default is false).
   * @returns An Observable of type D365GatewayResponse<boolean> representing the response from the server.
   */
  private postBatch(body: { value: string, batchId: string }, isQuiet = false): Observable<D365GatewayResponse<boolean>> {
    if (this.queryLogIsEnabled) this.logger.debug(body.toString());
    try {
      const gatewayRequest = this.d365GatewayService.prepareGatewayRequest(`$Batch::${body.batchId}`, HttpMethod.POST, body);
      return this.d365GatewayService.sendBatch(gatewayRequest, isQuiet);
    } catch (error) {
      console.error('Error making request:', error);
      throw error;
    }
  }

  /**
   * Sends a PATCH request to update an entity at the specified endpoint.
   *
   * @template T - The type of the response data.
   * @param {string} endpointQuery - The query string for the endpoint.
   * @param {unknown} [body=undefined] - The data to be sent in the request body.
   * @param {boolean} [isQuiet=false] - Indicates whether to suppress logging for the request.
   * @returns {Observable<D365GatewayResponse<T>>} An Observable that emits the response data.
   */
  private patchEntity<T>(endpointQuery: string, body: unknown = undefined, isQuiet = false): Observable<D365GatewayResponse<T>> {
    if (this.queryLogIsEnabled) this.logger.debug(endpointQuery);
    try {
      const gatewayRequest = this.d365GatewayService.prepareGatewayRequest(endpointQuery, HttpMethod.PATCH, body);
      if (this.queryLogIsEnabled) this.logger.displayObjectDebug(gatewayRequest, ' FoOadataService patchEntity : gatewayRequest');
      return this.d365GatewayService.sendGatewayRequest<T>(gatewayRequest, isQuiet);
    } catch (error) {
      console.error('Error making request:', error);
      throw error;
    }
  }

  /**
   * Deletes an entity based on the provided endpoint query.
   *
   * @template T - The type of the entity being deleted.
   * @param {string} endpointQuery - The query string for the endpoint.
   * @param {boolean} [isQuiet=false] - Indicates whether to suppress logging.
   * @returns {Observable<D365GatewayResponse<T>>} An Observable of the deleted entity response.
   */
  private deleteEntity<T>(endpointQuery: string, isQuiet = false): Observable<D365GatewayResponse<T>> {
    if (this.queryLogIsEnabled) this.logger.debug(endpointQuery);
    try {
      const gatewayRequest = this.d365GatewayService.prepareGatewayRequest(endpointQuery, HttpMethod.DELETE);
      if (this.queryLogIsEnabled) this.logger.displayObjectDebug(gatewayRequest, ' FoOadataService deleteEntity : gatewayRequest');
      return this.d365GatewayService.sendGatewayRequest<T>(gatewayRequest, isQuiet);
    } catch (error) { console.error('Error making request:', error); throw error; }
  }

  //#endregion

  //#region GET

  /**
   * Prepares a query string based on the provided parameters.
   *
   * @param {string[]} selectFields - An array of fields to select from the query.
   * @param {unknown} filterParams - The filter parameters to apply to the query.
   * @param {string[]} [orderBy=[]] - An array of fields to order the query by.
   * @param {string[]} [expand=[]] - An array of fields to expand in the query.
   * @param {number} [top] - The maximum number of records to retrieve.
   * @return {string} The prepared query string.
   */
  private prepareQuery(selectFields: string[], filterParams: unknown, orderBy: string[] = [], expand: string[] = [], top?: number): string {
    const query: Record<string, unknown> = {};

    if (selectFields.length > 0) {
      query['select'] = selectFields;
    }

    if (filterParams !== undefined) {
      query['filter'] = filterParams;
    }

    if (orderBy !== undefined && orderBy.length > 0) {
      query['orderby'] = orderBy.join(',');
    }

    if (expand !== undefined && expand.length > 0) {
      query['expand'] = expand.join(',');
    }

    if (top !== undefined && top !== null) {
      query['top'] = top;

    }

    return buildQuery(query);
  }
  /**
   * Retrieves data from the server based on the specified parameters.
   * @see https://docs.microsoft.com/en-us/dynamics365/fin-ops-core/dev-itpro/data-entities/odata
   * @param entityName - The name of the entity to retrieve data for.
   * @param selectFields - An array of fields to select from the entity.
   * @param filterParams - The filter parameters to apply to the data. Use odat-query to build the filter.
   * @see https://github.com/techniq/odata-query
   * @param orderBy - An array of fields to order the data by.
   * @param expand - An array of fields to expand in the data.
   * @param crossCompany - Indicates whether to retrieve data from multiple companies.
   * @param top - The maximum number of records to retrieve.
   * @returns An Observable that emits the retrieved data.
   */
  public getSomeWithFilterParams<T>(entityName: string, selectFields: string[] = [], filterParams: unknown = undefined, orderBy: string[] = [], expand: string[] = [], crossCompany: boolean = false, top: number | undefined = undefined): Observable<ODataArrayResult<T>> {
    const queryString = this.prepareQuery(selectFields, filterParams, orderBy, expand, top);

    let endpointQuery = '';
    endpointQuery = `${entityName}${queryString}${crossCompany ? '&cross-company=true' : ''}`;

    return this.GetEntities<T>(endpointQuery);

  }
  /**
   * Retrieves data from the server based on the specified parameters.
   *
   * @param {string} entityName - The name of the entity to retrieve data for.
   * @param {string[]} selectFields - An array of fields to select from the entity.
   * @param {string} filterString - The filter string to apply to the data.
   * @param {string[]} orderBy - An array of fields to order the data by.
   * @param {string[]} expand - An array of fields to expand in the data.
   * @param {boolean} crossCompany - Indicates whether to retrieve data from multiple companies.
   * @param {number | undefined} top - The maximum number of records to retrieve.
   * @returns {Observable<ODataArrayResult<T>>} An Observable that emits the retrieved data.
   */
  public getSomeWithFilterString<T>(entityName: string, selectFields: string[] = [], filterString: string = '', orderBy: string[] = [], expand: string[] = [], crossCompany: boolean = false, top: number | undefined = undefined): Observable<ODataArrayResult<T>> {
    const queryString = this.prepareQuery(selectFields, null, orderBy, expand, top);
    let endpointQuery = '';
    if (filterString !== '') {
      endpointQuery = `${entityName}${queryString}&$filter=${filterString}${crossCompany ? '&cross-company=true' : ''}`;
    } else {
      endpointQuery = `${entityName}${queryString}${crossCompany ? '&cross-company=true' : ''}`;
    }
    return this.GetEntities<T>(endpointQuery);
  }

  public getSomeWithCount<T>(entityName: string, selectFields: string[] = [],filterString: string = '', crossCompany: boolean = false, isQuiet = false): Observable<OdataCountResult<T>> {
    const queryString = this.prepareQuery(selectFields, null, [], [], 0);
    let endpointQuery = '';
    endpointQuery = `${entityName}${queryString}&$filter=${filterString}&$count=true${crossCompany ? '&cross-company=true' : ''}`;

    if (this.queryLogIsEnabled) this.logger.debug(endpointQuery);

    try {
      const gatewayRequest = this.d365GatewayService.prepareGatewayRequest(endpointQuery, HttpMethod.GET);
      return this.d365GatewayService.sendGatewayRequest<OdataCountResult<T>>(gatewayRequest, isQuiet).pipe(
        map((gatewayResponse: D365GatewayResponse<OdataCountResult<T>>) => gatewayResponse.response)
      );
    } catch (error) {
      console.error('Error making request:', error);
      throw error;
    }

  }

  //#endregion GET

  //#region POST

  /**
   * Posts an object to the specified entity endpoint.
   *
   * @param {string} entityName - The name of the entity to post the object to.
   * @param {boolean} [crossCompany=false] - Indicates whether to post the object across multiple companies.
   * @param {unknown} [body=undefined] - The object to post.
   * @param {boolean} [isQuiet=false] - Indicates whether to post the object quietly.
   * @returns {Observable<D365GatewayResponse<T>>} An Observable that emits the response after posting the object.
   */
  public postObject<T>(entityName: string, crossCompany: boolean = false, body: unknown = undefined, isQuiet = false): Observable<D365GatewayResponse<T>> {
    const endpointQuery = `${entityName}?${crossCompany ? '&cross-company=true' : ''}`;
    if (this.queryLogIsEnabled) this.logger.debug(endpointQuery);
    return this.postEntity<T>(endpointQuery, body, isQuiet);
  }

  /**
   * Posts a batch object to the server.
   *
   * @param {string} batchPlainText - The plain text of the batch object to post.
   * @param {boolean} [isQuiet=false] - Indicates whether to suppress logging for this operation.
   * @returns {Observable<D365GatewayResponse<boolean>>} An Observable that emits the response of the post operation.
   */
  public postBatchObject(batchPlainText: string, isQuiet = false): Observable<D365GatewayResponse<boolean>> {
    return this.postBatch({ value: batchPlainText, batchId: this.batchID }, isQuiet);
  }

  /**
   * Sends a POST request to a bound action endpoint with the provided parameters.
   *
   * @param {string} entityName - The name of the entity to perform the action on.
   * @param {KeyValue<string, string>[] | undefined} keys - The key-value pairs for identifying the entity.
   * @param {string} BoundActionName - The name of the bound action to execute.
   * @param {boolean} crossCompany - Indicates whether to perform the action across multiple companies.
   * @param {unknown} body - The data to send in the request body.
   * @param {boolean} isQuiet - Indicates whether to suppress logging for this request.
   * @return {Observable<D365GatewayResponse<T>>} An Observable that emits the response from the bound action request.
   */
  public postToBoudAction<T>(entityName: string, keys: KeyValue<string, string>[] | undefined, BoundActionName: string, crossCompany: boolean = false, body: unknown = undefined, isQuiet = false): Observable<D365GatewayResponse<T>> {
    let endpointQuery = "";
    if (keys && keys.length > 0) {
      const selector = this.generateStringKeyPairValues(keys);
      endpointQuery = `${entityName}(${selector})/Microsoft.Dynamics.DataEntities.${BoundActionName}?${crossCompany ? '&cross-company=true' : ''}`;
    } else {
      endpointQuery = `${entityName}/Microsoft.Dynamics.DataEntities.${BoundActionName}?${crossCompany ? '&cross-company=true' : ''}`;
    }

    if (this.queryLogIsEnabled) this.logger.debug(endpointQuery);

    return this.postEntity<T>(endpointQuery, body, isQuiet);

  }

  /**
   * Generates a string by mapping key-value pairs from the input array.
   * Each pair is formatted as 'key=value' and joined by a comma and space.
   *
   * @param values An array of key-value pairs to be processed.
   * @returns A string containing the formatted key-value pairs.
   */
  generateStringKeyPairValues(values: KeyValue<string, unknown>[]): string {
    return values.map(({ key, value }) => `${String(key)}=${value}`).join(' , ');
  }

  /**
   * Sends a PATCH request to update an object of type T in the specified entity.
   *
   * @param {string} entityName - The name of the entity to update.
   * @param {KeyValue<string, unknown>[]} keys - An array of key-value pairs to identify the object to update.
   * @param {unknown} [body=undefined] - The data to update the object with.
   * @returns {Observable<D365GatewayResponse<T>>} An Observable that emits the response after updating the object.
   */
  public patchObject<T>(entityName: string, keys: KeyValue<string, unknown>[], body: unknown = undefined): Observable<D365GatewayResponse<T>> {
    const selector = this.generateStringKeyPairValues(keys);
    const endpointQuery = `${entityName}(${selector})?&cross-company=true`;
    if (this.queryLogIsEnabled) this.logger.debug(endpointQuery);
    return this.patchEntity<T>(endpointQuery, body);
  }

  //#endregion POST


  //#region PATCH
  //#endregion PATCH

  //#region DELETE
  /**
   * Deletes an object of type T from the specified entity.
   *
   * @param {string} entityName - The name of the entity from which to delete the object.
   * @param {KeyValue<string, unknown>[]} options - An array of key-value pairs to identify the object to delete.
   * @param {boolean} [isQuiet=false] - Indicates whether to suppress logging for this operation.
   * @returns {Observable<D365GatewayResponse<T>>} An Observable that emits the response after deleting the object.
   */
  public deleteObject<T>(entityName: string, options: KeyValue<string, unknown>[], isQuiet = false): Observable<D365GatewayResponse<T>> {
    const params = this.generateStringKeyPairValues(options);
    const endpointQuery = `${entityName}(${params})?&cross-company=true`;
    if (this.queryLogIsEnabled) this.logger.debug(endpointQuery);
    return this.deleteEntity<T>(endpointQuery, isQuiet);
  }

  //#endregion DELETE


}



