import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  /**
   * Returns the storage object based on the specified storage type.
   *
   * @param storageType - The type of storage to retrieve (either 'local' or 'session').
   * @returns The storage object corresponding to the specified type.
   */
   getStorage(storageType: StorageType): Storage {
    return storageType === StorageType.LOCAL ? localStorage : sessionStorage;
  }

  /**
   * Sets an item in the specified storage type with the given key and value.
   *
   * @param storageType The type of storage to set the item in (local or session).
   * @param key The key under which the value will be stored.
   * @param value The value to be stored in the storage.
   */
  setItem<T>(storageType: StorageType, key: CacheKeys, value: T): void {
    const storage = this.getStorage(storageType);
    storage.setItem(key, JSON.stringify(value));
  }


  /**
   * Retrieves an item from the specified storage type using the provided key.
   *
   * @template T - The type of the item to retrieve.
   * @param storageType - The type of storage to retrieve the item from (local or session).
   * @param key - The key associated with the item to retrieve.
   * @returns The retrieved item of type T if found, or null if the item does not exist.
   */
  getItem<T>(storageType: StorageType, key: CacheKeys): T | null {
    const storage = this.getStorage(storageType);
    const item = storage.getItem(key);
    return item ? JSON.parse(item) as T : null;
  }

/**
 * Removes an item from the specified storage type using the provided key.
 *
 * @param storageType The type of storage to remove the item from (local or session).
 * @param key The key of the item to be removed.
 */
  removeItem(storageType: StorageType, key: CacheKeys): void {
    const storage = this.getStorage(storageType);
    storage.removeItem(key);
  }


  /**
   * Clears the storage based on the specified storage type.
   *
   * @param storageType - The type of storage to be cleared (local or session).
   */
  clear(storageType: StorageType): void {
    const storage = this.getStorage(storageType);
    storage.clear();
  }

  /**
 * Checks if an item exists in the specified storage type and that its value is not null or empty.
 *
 * @param storageType - The type of storage to check the item in (local or session).
 * @param key - The key of the item to verify.
 * @returns True if the item exists and is not null or empty, false otherwise.
 */
haveItem(storageType: StorageType, key: CacheKeys): boolean {
  const storage = this.getStorage(storageType);
  const item = storage.getItem(key);
  return item !== null && item !== '';
}

}
export enum StorageType {
  LOCAL = 'local',
  SESSION = 'session'
}
export enum CacheKeys {
  PREF_USER_LANG = 'PREF_USER_LANG'
  ,ORDER_TEMPLATE_TO_PAST = 'ORDER_TEMPLATE'
  ,USER_CUST = 'USER_CUSTOMERS'
  ,USEABLE_CUST ='USEABLE_CUSTOMERS'
  // Add Other ...
}
