import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { type Observable, map } from 'rxjs';
import { LoggerService } from '@services/log/logger.service';

import { HttpMethod } from '@app/core/models/dto/gateway-midleware/HttpMethod.enum';
import { HttpStatusCode } from '@app/core/models/dto/gateway-midleware/HttpStatusCode.enum';
import { type D365GatewayRequest } from '@app/core/models/dto/gateway-midleware/d365-gateway-request';
import { type D365GatewayResponse } from '@app/core/models/dto/gateway-midleware/d365-gateway-response';
import { environment } from '@environments/environment';
import { InterceptorLabels } from '@app/core/models/enums/interceptors-label.enum';
import { AlertService } from '@app/shared/ui-components/alert/service/alert.service';
import { LoaderService } from '@app/shared/ui-components/loader/service/loader.service';
import { StringValidator } from '@app/shared/utils/validators/string.validator';
import { D365SPLSalesOrderHeaderV2BoundAction } from '@app/core/models/dto/D365Entities.enum';
import { TranslateService } from '@ngx-translate/core';
import { TOAST_STATE } from '@app/shared/ui-components/alert/types/alert.type';



export const GATEWAY_TARGET = 'gateway-target';
export const TARGET = 'target';
export const D365GW = 'd365gw';
@Injectable({
  providedIn: 'root'
})
export class D365GatewayService {
  private readonly _baseUrl: string = environment.gateway.url;
  private readonly _mailEP: string = environment.gateway.segments.mail;
  private readonly _requestEP: string = environment.gateway.segments.request;
  private readonly _batchEP: string = `${this._requestEP}/${environment.gateway.segments.sendBatch}`;
  private readonly HttpClient: HttpClient = inject(HttpClient);
  private readonly logger: LoggerService = inject(LoggerService);
  alertService: AlertService = inject(AlertService);
  loaderService: LoaderService = inject(LoaderService);
  translateService: TranslateService = inject(TranslateService);

  /**
   * Prepares a D365GatewayRequest object with the provided query, method, and optional body.
   * @param query The query string for the request.
   * @param method The HttpMethod enum value representing the request method.
   * @param body The body of the request, defaults to undefined.
   * @returns The prepared D365GatewayRequest object.
   */
  public prepareGatewayRequest(query: string, method: HttpMethod, body: unknown = undefined): D365GatewayRequest {
    const request = {
      Method: HttpMethod[method],
      Query: query,
      Body: body
    };
    return request;
  }

  /**
   * Sends a request to the D365 gateway at the specified endpoint.
   *
   * @param endpoint The endpoint to send the request to.
   * @param request The D365GatewayRequest object containing method, query, and body.
   * @param isQuiet Flag to indicate if the response should be returned without further processing.
   * @returns An Observable of D365GatewayResponse<T> representing the response from the gateway.
   */
  private sendtoGateway<T>(endpoint: string, request: D365GatewayRequest, isQuiet = false): Observable<D365GatewayResponse<T>> {
    const url = this._baseUrl + endpoint;
    const stringifiedRequest = JSON.stringify(request);
    return this.HttpClient.post<D365GatewayResponse<T>>(url, stringifiedRequest, { headers: this.getHeaders([InterceptorLabels.CRUD_REQUESTS]) })
      .pipe(
        map((gatewayResponse: D365GatewayResponse<T>) => {
          if (isQuiet) return gatewayResponse;
          return this.CheckAndReturnGatewayResult(gatewayResponse);
        }
        )
      );
  }

  /**
   * Sends an email using the D365 gateway service.
   *
   * @param request The D365GatewayRequest object containing the email details.
   * @param isQuiet A boolean flag indicating whether to suppress alerts and toasts (default is false).
   * @returns An Observable of D365GatewayResponse<T> representing the response from sending the email.
   */
  public sendMail<T>(request: D365GatewayRequest, isQuiet = false): Observable<D365GatewayResponse<T>> {
    return this.sendtoGateway<T>(this._mailEP, request, isQuiet);
  }

  /**
   * Sends a gateway request with the provided data.
   *
   * @param request - The D365GatewayRequest object containing the request details.
   * @param isQuiet - A boolean flag indicating whether to suppress alerts and toasts (default is false).
   * @returns An Observable of D365GatewayResponse<T> representing the response from the gateway.
   */
  public sendGatewayRequest<T>(request: D365GatewayRequest, isQuiet = false): Observable<D365GatewayResponse<T>> {
    return this.sendtoGateway<T>(this._requestEP, request, isQuiet);
  }

  /**
   * Sends a batch request to the D365 gateway.
   *
   * @param request - The D365GatewayRequest object containing the method, query, and body of the request.
   * @param isQuiet - A boolean flag indicating whether to suppress alerts and toasts (default is false).
   * @returns An Observable of D365GatewayResponse<boolean> representing the response from the gateway.
   */
  public sendBatch(request: D365GatewayRequest, isQuiet = false): Observable<D365GatewayResponse<boolean>> {
    return this.sendtoGateway<boolean>(this._batchEP, request, isQuiet);
  }

  /**
   * Checks the gateway response and handles different HTTP status codes accordingly.
   * Returns the processed gateway response.
   * @param gatewayResponse The response object from the gateway
   * @returns The processed gateway response after handling different status codes
   */
  private CheckAndReturnGatewayResult<T>(gatewayResponse: D365GatewayResponse<T>): D365GatewayResponse<T> {
    let reponseMessage = '';
    if (gatewayResponse === undefined) {
      this.logger.error('Error : No response');
      this.alertService.show(TOAST_STATE.warning, true, 'No Response', 'Error : No response');
      return {} as unknown as D365GatewayResponse<T>;
    }
    switch (gatewayResponse.statusCode) {
      case HttpStatusCode.OK:
        if (gatewayResponse.method === HttpMethod.POST) {
          reponseMessage = this.parseMessage(gatewayResponse.response as string);
          return this.traceAndReturnResponse(gatewayResponse, TOAST_STATE.success, 'POST OK', reponseMessage);
        }
        return gatewayResponse;

      case HttpStatusCode.Created:
        return this.traceAndReturnResponse(gatewayResponse, TOAST_STATE.success, 'Creation OK');

      case HttpStatusCode.NoContent:
        return this.traceAndReturnResponse(gatewayResponse, TOAST_STATE.info, 'No content');


      case HttpStatusCode.NotFound:
        reponseMessage = this.parseMessage(gatewayResponse.response as string);
        this.logger.info('Error : Not found');
        this.alertService.show(TOAST_STATE.info, true, 'Error : Not found', `\n ${this.getErrorWithCodeMsg(gatewayResponse.statusCode)}\n \n ${reponseMessage}`);
        return this.getdefaultResponseValue<T>();

      case HttpStatusCode.Unauthorized:
        reponseMessage = this.parseMessage(gatewayResponse.response as string);
        this.logger.error('Error : Gateway unauthorized');
        this.alertService.show(TOAST_STATE.warning, true, `${this.getErrorWithCodeMsg(gatewayResponse.statusCode)} : unauthorized`, `Gateway : UnAuthorized ${this.getReloadAndRetryLaterMsg()} \n ${this.getErrorWithCodeMsg(gatewayResponse.statusCode)}\n \n ${reponseMessage}`);
        document.location.reload();
        throw new Error(`${this.getErrorWithCodeMsg(gatewayResponse.statusCode)}`);
      // return this.getdefaultResponseValue<T>();

      case HttpStatusCode.BadRequest:
        reponseMessage = this.parseMessage(gatewayResponse.response as string);
        const title = `${this.getErrorWithCodeMsg(gatewayResponse.statusCode)} :  Gateway Bad Request`;
        const actionMsg = `${this.getContactYourADminMsg()} \n ${this.getErrorWithCodeMsg(gatewayResponse.statusCode)}\n`;
        /** we need to stop loading in this case */
        this.loaderService.stopLoading();

        /** 26733 cusCatalog error management  */
        if(this.isCatalogItemRequest(gatewayResponse.query)){
          this.alertService.show(TOAST_STATE.warning, true, title, `Gateway : Bad request. \n ${this.getEcoResProductErrorMsg()} \n ${reponseMessage} \n ${actionMsg}\n `, 0);
          gatewayResponse.response = {} as T;
          return gatewayResponse;
        }

        this.logger.error('Error : Gateway Bad Request');
        this.alertService.show(TOAST_STATE.warning, true, title , `${this.getErrorWithCodeMsg(gatewayResponse.statusCode)} : Server not found or bad request.  \n ${actionMsg} \n ${reponseMessage}`);
        throw new Error(`${this.getErrorWithCodeMsg(gatewayResponse.statusCode)}`);



      case HttpStatusCode.InternalServerError:
        reponseMessage = this.parseMessage(gatewayResponse.response as string);

        this.logger.error('Error : Internal server error');
        this.alertService.show(TOAST_STATE.danger, true, `${this.getErrorWithCodeMsg(gatewayResponse.statusCode)} : Internal server error`, `${this.getReloadAndRetryLaterMsg} \n ${this.getErrorWithCodeMsg(gatewayResponse.statusCode)}\n \n ${reponseMessage}`);
        document.location.reload();
        throw new Error(`${this.getErrorWithCodeMsg(gatewayResponse.statusCode)}`);

      default:
        this.logger.error(`${this.getErrorWithCodeMsg(gatewayResponse.statusCode)}`);
        reponseMessage = this.parseMessage(gatewayResponse.response as string);
        /** we need to stop loading in this case */
        this.loaderService.stopLoading();
        this.alertService.show(TOAST_STATE.warning, true, `${this.getErrorWithCodeMsg(gatewayResponse.statusCode)}`, `${this.getReloadAndRetryLaterMsg} \n ${this.getErrorWithCodeMsg(gatewayResponse.statusCode)}\n \n ${reponseMessage}`);
        throw new Error(`${this.getErrorWithCodeMsg(gatewayResponse.statusCode)}`);
    }
  }
  public getdefaultResponseValue<T>(): D365GatewayResponse<T> {
    return {} as unknown as D365GatewayResponse<T>;
  }

  /**
   * Parses a JSON message to extract an error message.
   *
   * @param message - The JSON message to be parsed.
   * @returns The extracted error message, or an empty string if parsing fails.
   */
  private parseMessage(message: string): string {
    console.log(message, 'JDEBUG parseMessage');
    let errorMessage = '';
    try {
      if(message != ''){
        const parsedMessage = JSON.parse(message);
        errorMessage = parsedMessage.error.innererror.message;
      }
      return errorMessage;
    } catch (error) {
      this.logger.displayObjectError(error as Error, 'Erreur de parsing JSON :');
      return errorMessage;
    }
  }
  private getHeaders(ressource: InterceptorLabels[] | undefined = undefined): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Target', 'application/json');
    if (ressource !== undefined) {
      httpHeaders = httpHeaders.set(GATEWAY_TARGET, ressource);
    }
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return httpHeaders;
  }
  private traceAndReturnResponse<T>(gatewayResponse: D365GatewayResponse<T>, toastLevel: string, title: string, msg = ''): D365GatewayResponse<T> {
    //this.logger.displayObjectDebug(gatewayResponse, title);
    this.alertService.show(toastLevel, false, title, msg);
    return gatewayResponse;
  }

  private isCatalogItemRequest(query : string){
    return StringValidator.haveValue(query,D365SPLSalesOrderHeaderV2BoundAction.CustCatalog) ||
    StringValidator.haveValue(query,D365SPLSalesOrderHeaderV2BoundAction.GetItems);
  }

  getReloadAndRetryLaterMsg(){
    return this.translateService.instant('GL.MSG.ReloadOrRetryLater');
  }
  getContactYourADminMsg(){
    return this.translateService.instant('GL.MSG.ContactYourAdmin');
  }
  getErrorWithCodeMsg(code : HttpStatusCode){
    return `${this.translateService.instant('GL.LABELS.Error')} ${code}`;
  }
  getEcoResProductErrorMsg(){
    return `${this.translateService.instant('GL.MSG.EcoResProductError')}`;
  }


}
