import { IconName } from "@fortawesome/fontawesome-svg-core";

export const TOAST_STATE = {
  success: 'success',
  warning: 'warning',
  danger: 'danger',
  info: 'info',
};

export const ALERT_ICONS = {
  success: 'check-circle' as IconName,
  warning: 'exclamation-triangle' as IconName,
  danger: 'exclamation-circle' as IconName,
  info: 'info-circle' as IconName,
};
