/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { LogLevel } from '@app/core/models/enums/log-level.enum';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private _currentLevel: LogLevel = environment.logLevel;
  public get currentLevel (): LogLevel {
    return this._currentLevel;
  }

  public set currentLevel (value: LogLevel) {
    this._currentLevel = value;
  }

  private shouldLog (level: LogLevel): boolean {
    return level >= this.currentLevel;
  }

  debug (message: string): void {
    if (this.shouldLog(LogLevel.Debug)) {
      console.debug('Debug:', message);
    }
  }

  info (message: string): void {
    if (this.shouldLog(LogLevel.Info)) {
      console.info('Info:', message);
    }
  }

  warn (message: string): void {
    if (this.shouldLog(LogLevel.Warn)) {
      console.warn('Warning:', message);
    }
  }

  error (message: string, stack?: string): void {
    if (this.shouldLog(LogLevel.Error)) {
      console.error('Error:', message);
      if (stack != null) {
        console.error(stack);
      }
    }
  }

  // https://developer.mozilla.org/fr/docs/Web/API/console/table_static
  displayArrayInfo (arr: Object, msg: string = ''): void {
    let info: string = 'info';
    if (this.shouldLog(LogLevel.Info)) {
      if (msg !== '') info = msg;
      console.info(info);
      console.table(arr);
    }
  }

  displayObjectInfo (obj: Object, msg: string = ''): void {
    let info: string = 'info';
    if (this.shouldLog(LogLevel.Info)) {
      if (msg !== '') info = msg;
      console.info(info, obj);
    }
  }

  displayArrayDebug (arr: Object, msg: string = ''): void {
    let info: string = 'info';
    if (this.shouldLog(LogLevel.Info)) {
      if (msg !== '') info = msg;
      console.debug(info);
      console.table(arr);
    }
  }

  displayObjectDebug (obj: Object, msg: string = ''): void {
    let info: string = 'info';
    if (this.shouldLog(LogLevel.Info)) {
      if (msg !== '') info = msg;
      console.debug(info, obj);
    }
  }

  displayObjectError (obj: Object, msg: string = ''): void {
    this.error(msg);
    console.error(obj);
  }

  displayArrayError (arr: Object, msg: string = ''): void {
    this.error(msg);
    console.error(arr);
  }
}
